export default {
	getRaffleOrders: {
		url: '/raffle/orders',
		method: 'get',
		params: {},
		headers: {}
	},
	raffleOrderSelect: {
		url: '/raffle/order-select',
		method: 'post',
		params: {},
		headers: {}
	},
	raffleMultipleOrderSelect: {
		url: '/raffle/multiple/orders-select',
		method: 'post',
		params: {},
		headers: {}
	},
	selectRaffleWinner: {
		url: '/raffle/winner/:order',
		method: 'post',
		params: {},
		headers: {}
	},
	gerRaffleWinners: {
		url: '/raffle/winners/all',
		method: 'get',
		params: {},
		headers: {}
	},
}