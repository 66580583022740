import axios from "./modules/config"

import store from '../../store'
import * as actions from "../../store/actions"

import authRequests from './modules/API/auth'
import ordersRequests from './modules/API/orders'
import paymentRequests from './modules/API/payment'
import raffleRequests from './modules/API/raffle'
import userInfoRequests from './modules/API/userProfile'
import faqRequests from './modules/API/faq'
import banksRequests from './modules/API/banks'
import subscribeToPushNotifications from './modules/API/pushNotifications'

class API {
	constructor() {
		let cancelToken = axios.CancelToken
		this._source = cancelToken.source()

		this._axios = axios

		this._requests = {
			...authRequests,
			...ordersRequests,
			...paymentRequests,
			...raffleRequests,
			...userInfoRequests,
			...faqRequests,
			...banksRequests,
			...subscribeToPushNotifications
		}
	}

	_handleMethodParams = (method, params) => {
		switch(method) {
			case 'get': return { params }
			case 'post': return params
			default: return {}
		}
	}

	cancelRequest = () => this._source.cancel('Response is canceled')

	call = (reqType, urlParam = '', customParams, customHeaders) => {
		const request = this._requests[reqType],
			headers = { ...request.headers, ...customHeaders},
			params = this._handleMethodParams(request.method,  { ...request.params, ...customParams })

		store.dispatch(actions.setProgressBar(0))
		return new Promise(
			(resolve, reject) => {
				this._axios[request.method]
					(
						request.url + urlParam,
						params,
						{ headers },
						{ cancelToken: this._source.token }
					)
					.then(({data}) => resolve(data))
					.catch(err => reject(err))
					.then(() => store.dispatch(actions.setProgressBar(100)))
			}
		)
	}
}

export default new API

