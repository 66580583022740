import React, { Component } from 'react'

export default class ErrorBoundary extends Component {
	state = {
		hasError: false,
		error: null,
		info: null
	}

	componentDidCatch(error, info) {
		this.setState({
			hasError: true,
			error,
			info
		})
	}

	displayFallbackUI = () => {
		return <h1 className={'text-center'}>Something went wrong.</h1>
	}

	render() {
		return this.state.hasError ? this.displayFallbackUI() : this.props.children;
	}
}