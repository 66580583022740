export default {
    getBanksIdin: {
        url: '/idin/banks',
		method: 'get',
		params: {},
		headers: {
			'Authorization': process.env.REACT_APP_AUTH_HEADER
		}
	},
	authenticateIdin: {
		url: '/idin/authenticate',
		method: 'post',
		params: {},
		headers: {
			'Authorization': process.env.REACT_APP_AUTH_HEADER
		}
	},
	getStatusIdin: {
		url: '/idin/status',
		method: 'post',
		params: {},
		headers: {
			'Authorization': process.env.REACT_APP_AUTH_HEADER
		}
	}
}