import * as actionTypes from '../actions/actionTypes'
import Helper from '../../helper/helperScripts'

const initialState = {
	token: {}
}

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.AUTH: return storeToken(state, action)
		case actionTypes.REMOVE_TOKEN: return removeToken(state)
		default: return state;
	}
}

let storeToken = (state, { token }) => {
	return Helper.updateObject(state, { token })
}, removeToken = (state) => {
	return Helper.updateObject(state, { token: {} })
}

export default reducer