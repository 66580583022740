import { AUTH, REMOVE_TOKEN } from '../actionTypes'
import API from "../../../helper/http/apiCalls"

export const setToken = (token) => {
	localStorage.setItem('token', JSON.stringify(token))
	return {
		type: AUTH,
		token
	}
}

export const removeToken = () => {
	localStorage.removeItem('token')
	return {
		type: REMOVE_TOKEN
	}
}

export const sendAuthEmail = (email, callback, recaptcha) => {
	return async (dispatch, getState) => {
		try {
			let emailCheckRes = await API.call('sendMailCheck', '', { email, origin: 'web' , recaptcha })

			callback(emailCheckRes)
		} catch(err) { console.error(err) }
	}
}

export const employeeLogin = (data, callback) => {
	return async (dispatch, getState) => {
		try {
			let loginData = await API.call('employeeLogin', '', data)

			callback(loginData)
		} catch(err) { console.error(err) }
	}
}

export const getToken = (emailID, history) => {
	return async (dispatch, getState) => {
		try {
			let emailIDCheckRes = await API.call('checkEmailID', '', null, { 'Authorization': emailID })

			if (emailIDCheckRes.code === 1000) {
				if (emailIDCheckRes.access_token) {
					dispatch(setToken(emailIDCheckRes))	
				} else {
					history.push('/errortoken')
				}
			} else {
				history.push('/login')
				window.location.reload(true)
			}

		} catch(err) { console.error(err) }
	}
}