import React, { Component } from 'react'
import { Route, Switch, withRouter, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
// import PropTypes from 'prop-types'

//hoc
import Layout from './components/hoc/Layout/Layout'
import ErrorBoundary from './components/hoc/ErrorBoundary/ErrorBoundary'
import asyncComponent from './components/hoc/asyncComponent/asyncComponent'

// Components
const asyncLogin = asyncComponent(() =>  import('./views/Login/Login'))
const asyncLoginEmployee = asyncComponent(() =>  import('./views/LoginEmployee/LoginEmployee'))
const asyncErrorToken = asyncComponent(() =>  import('./components/hoc/ErrorBoundary/ErrorTokenVerification'))
const asyncDashboard = asyncComponent(() =>  import('./views/Dashboard/Dashboard'))
const asyncOrders = asyncComponent(() =>  import('./views/Orders/Orders'))
const asyncOrderDetails = asyncComponent(() =>  import('./views/OrderDetails/OrderDetails'))
const asyncPay = asyncComponent(() =>  import('./views/Pay/Pay'))
const asyncPaymentResponse = asyncComponent(() =>  import('./views/PaymentResponse/PaymentResponse'))
const asyncPaymentResponseMobile = asyncComponent(() =>  import('./views/PaymentResponseMobile/PaymentResponseMobile'))
const asyncRaffleInvoice = asyncComponent(() =>  import('./views/RaffleInvoice/RaffleInvoice'))
const asyncFaq = asyncComponent(() =>  import('./views/Faq/Faq'))
const asyncUserEdit = asyncComponent(() =>  import('./views/UserEdit/UserEdit'))
const asyncTokenVerification = asyncComponent(() => import('./views/TokenVerification/TokenVerification'))
const asyncHistory = asyncComponent(() => import('./views/History/History'))
const asyncLoginIdin = asyncComponent(() => import('./views/Idin/LoginIdin'))
const asyncSuccessIdin = asyncComponent(() => import('./views/Idin/components/SuccessIdin'))
const asyncPushNotifications = asyncComponent(() => import('./views/PushNotifications/PushNotifications'))

class App extends Component {
	render() {
		let routes = (
			<Switch>
				<Route path="/email-id=:emailID&origin=:origin" exact component={asyncTokenVerification} />
				<Route path="/login" exact component={asyncLogin} />
				<Route path="/errortoken" exact component={asyncErrorToken} />
				<Route path="/auth/idin" exact component={asyncLoginIdin} />
				<Route path="/auth/idin/return" exact component={asyncSuccessIdin} />
				<Route path="/login/employee" exact component={asyncLoginEmployee} />
				<Route path="/mobile/payment-response" exact component={asyncPaymentResponseMobile} />
				<Redirect to="/login" />
			</Switch>
		)
		if (this.props.isLogged) {
			routes = (
				<Switch>
					<Route path="/" exact component={asyncDashboard} />
					<Route path="/facturen" exact component={asyncOrders} />
					<Route path="/facturen/:id" exact component={asyncOrderDetails} />
					<Route path="/payment" exact component={asyncPay} />
					<Route path="/betaalde-facturen" exact component={asyncHistory} />
					<Route path="/payment/response" exact component={asyncPaymentResponse} />
					<Route path="/winactie/facturen" exact component={asyncRaffleInvoice} />
					<Route path="/faq/:id" exact component={asyncFaq} />
					<Route path="/user/edit" exact component={asyncUserEdit} />
					<Route path="/mobile/payment-response" exact component={asyncPaymentResponseMobile} />
					<Route path="/push-notifications" exact component={asyncPushNotifications} />
					<Redirect to="/" />
				</Switch>
			)
		}

		return <ErrorBoundary><Layout isLogged={this.props.isLogged}>{routes}</Layout></ErrorBoundary>
  }
}

const mapStateToProps = state => {
	return {
		isLogged: !!state.authReducer.token.access_token
	};
};

export default withRouter(connect(mapStateToProps, null)(App));
