export default {
	getOrders: {
		url: '/order/all',
		method: 'get',
		params: {},
		headers: {}
	},
	getPayedOrders: {
		url: '/order/paid',
		method: 'get',
		params: {},
		headers: {}
	},
	getOrderDetails: {
		url: 'order/details/',
		method: 'get',
		params: {},
		headers: {}
	},
	getOrderSteps: {
		url: 'order/on-each-step',
		method: 'get',
		params: {},
		headers: {}
	},
	getPdfFileAccess: {
		url: 'order/file-access-token',
		method: 'get',
		params: {},
		headers: {}
	}
}