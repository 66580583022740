export default {
	getUserInfo: {
		url: '/profile/index',
		method: 'get',
		params: {},
		headers: {}
	},
	editUserInfo: {
		url: '/profile/update',
		method: 'post',
		params: {},
		headers: {}
	}
}