import axios from 'axios'

import store from '../../../store'
import * as actions from "../../../store/actions";

axios.defaults.baseURL = process.env.REACT_APP_API_URL

axios.interceptors.request.use(
	request => {
		let { authReducer } = store.getState()
		if (authReducer.token && authReducer.token.access_token) {
			request.headers['Authorization'] = `Bearer ${authReducer.token.access_token}`
		}
		return request
	}
)

axios.interceptors.response.use(
	(response) => resHandler(response),
	(error) => errHandler(error)
)

let resHandler = (response) => response,
	errHandler = (error) => {
		if (error.response && error.response.status === 401) {
			store.dispatch(actions.removeToken())
			window.location.reload(true)
		}
		return error;
	}

export default axios
