import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'

import middleware from './middleware/index'
import authReducer from './reducers/auth'
import paymentReducer from './reducers/payment'
import sidebarReducer from './reducers/sidebar'
import userInfoReducer from './reducers/userInfo'
import progressbarReducer from './reducers/progressbar'

const rootReducer = combineReducers({
	authReducer,
	paymentReducer,
	sidebarReducer,
	userInfoReducer,
	progressbarReducer
})

const store = createStore(rootReducer, applyMiddleware(...middleware, thunk))

export default store
