import * as actionTypes from '../actions/actionTypes'
import Helper from '../../helper/helperScripts'

const initialState = {
	invoices: []
}

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SAVE_INVOICE_FOR_PAYMENT: return saveInvoicesForPayment(state, action)
		case actionTypes.REMOVE_INVOICE_FOR_PAYMENT: return removeInvoicesForPayment(state)
		default: return state;
	}
}

let saveInvoicesForPayment = (state, { invoices, callback }) => {
	state.invoices = [{...invoices}]
	callback('/payment')
	return state
}, removeInvoicesForPayment = (state) => {
	state.invoices = []
	return state
}

export default reducer