export default {
	sendMailCheck: {
		url: '/send-email',
		method: 'post',
		params: {},
		headers: {
			'Authorization': process.env.REACT_APP_AUTH_HEADER
		}
	},
	employeeLogin: {
		url: '/employee/send-email',
		method: 'post',
		params: {},
		headers: {
			'Authorization': process.env.REACT_APP_AUTH_HEADER
		}
	},
	checkEmailID: {
		url: '/authorize',
		method: 'post',
		params: {
			client_id: process.env.REACT_APP_CLIENT_ID,
			client_secret: process.env.REACT_APP_CLIENT_SECRET
		},
		headers: {}
	},
}