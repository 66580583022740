import { SAVE_INVOICE_FOR_PAYMENT, REMOVE_INVOICE_FOR_PAYMENT } from '../actionTypes'

export const saveInvoicesForPayment = (invoices, callback) => {
	return {
		type: SAVE_INVOICE_FOR_PAYMENT,
		invoices,
		callback
	}
}

export const removeInvoicesForPayment = () => {
	return {
		type: REMOVE_INVOICE_FOR_PAYMENT
	}
}