export default {
	number_format(number, decimals, decPoint, thousandsSep) {
		number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
		let n = !isFinite(+number) ? 0 : +number,
			prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
			sep = (typeof thousandsSep === 'undefined') ? ',' : thousandsSep,
			dec = (typeof decPoint === 'undefined') ? '.' : decPoint;

		function toFixedFix(n, prec) {
			let k = Math.pow(10, prec);
			return '' + (Math.round(n * k) / k).toFixed(prec);
		}

		let s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
		if (s[0].length > 3) {
			s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
		}
		if ((s[1] || '').length < prec) {
			s[1] = s[1] || '';
			s[1] += new Array(prec - s[1].length + 1).join('0');
		}
		return s.join(dec)
	},

	scrollTo(target, pos) {
		// element, to, duration
		// if (duration <= 0) return;
		// let difference = to - element.scrollTop;
		// let perTick = difference / duration * 10;
		//
		// setTimeout(() => {
		// 	element.scrollTop = element.scrollTop + perTick;
		// 	if (element.scrollTop === to) return;
		// 	this.scrollTo(element, to, duration - 10);
		// }, 10);
		document
			.getElementById(target)
			.scrollIntoView({ behavior: "smooth", block: pos, inline: pos })
	},
	fillEmailIframe(target, content) {
		let iframe = document.getElementById(target),
			doc = iframe.document

		if (iframe.contentDocument) { doc = iframe.contentDocument }
		else if (iframe.contentWindow) { doc = iframe.contentWindow.document }

		doc.open()
		doc.writeln(content);

		[...doc.getElementsByTagName('a')].forEach((a) => a.target = '_blank')

		doc.close()
	},
	checkValidity( value, rules ) {
		let isValid = true

		if (!rules) { return true }

		if (rules.required) {
			isValid = value.trim() !== '' && isValid
		}
		if (rules.minLength) {
			isValid = value.length >= rules.minLength && isValid
		}
		if (rules.maxLength) {
			isValid = value.length <= rules.maxLength && isValid
		}
		if (rules.isEmail) {
			const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
			isValid = pattern.test( value ) && isValid
		}
		if (rules.isNumeric) {
			const pattern = /^\d+$/
			isValid = pattern.test( value ) && isValid
		}
		return isValid;
	},
	updateObject(oldObject, updatedProperties) {
		return {
			...oldObject,
			...updatedProperties
		}
	},
	parseCssClasses(styles, classNames) {
		let classes = classNames.split(' ').map(item => styles[item])

		return classes.join(' ')
	}
}