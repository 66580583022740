import React from 'react';
import {Link} from 'react-router-dom'
import ProgressBar from 'react-progress-bar-plus'

import {connect} from "react-redux";
import * as actions from "../../../store/actions";

const Header = (props) => {
	let progressBarPercent = 0

	return (
		<div className="header">
			<div className="container-fluid">
				<div className="row no-gutters">
					<div className="col-6">
						<Link to={'/'} className="header__logo-wrap">
							<img className="header__logo" src={process.env.REACT_APP_CDN_BASE_URL + 'svg/billink-logo-default.svg'} alt="Logo" />
						</Link>
					</div>
					<div className="col-6 d-lg-none">
						<button
							onClick={props.toggleMenu}
							type={'button'}
							className="header__menu-toggle d-flex align-content-center justify-content-center"
						>
							<span className="header__menu-toggle-inner">
				                <i className="header__menu-toggle-icon header__menu-toggle-icon--inactive fas fa-bars"></i>
				                <i className="header__menu-toggle-icon header__menu-toggle-icon--active fas fa-times"></i>
								&nbsp;&nbsp;
								Menu
							</span>
						</button>
					</div>
				</div>
			</div>
			<ProgressBar
				percent={props.showProgressBar}
				autoIncrement={true}
			/>
		</div>
	)
}

let mapStateToProps = state => {
	return {
		showProgressBar: state.progressbarReducer.progressBarValue
	}
}, dispatchToProps = dispatch => {
	return {
		toggleMenu: () => dispatch(actions.toggleSidebarMenu())
	}
}

export default connect(mapStateToProps, dispatchToProps)(Header)
