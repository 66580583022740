import * as actionTypes from '../actions/actionTypes'
import Helper from '../../helper/helperScripts'
import {SET_PROGRESS_BAR_VALUE} from "../actions/actionTypes";

const initialState = {
	progressBarValue: -1
}

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_PROGRESS_BAR_VALUE: return setProgressBarValue(state, action)
		default: return state;
	}
}

let setProgressBarValue = (state, { value }) => {
	return {progressBarValue: value}
}

export default reducer