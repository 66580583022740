import React from 'react';

import Aux from '../aux/Aux'

import Header from '../../core/Header/Header'
import Sidebar from '../../core/Sidebar/Sidebar'

export default (props) => {
	let whatToShow = () => {
		let content = null

		if (props.isLogged) {
			content = (
				<Aux>
					<Header/>
					<div className="main container-fluid">
						<div className="row no-gutters flex-lg-nowrap">
							<Sidebar />
							<div className="col content">
								{ props.children }
							</div>
						</div>
					</div>
				</Aux>
			)
		}

		return content || props.children
	}

	return whatToShow()
}