export default {
    subscribeToPushNotifications: {
        url: '/push-notification/subscribe',
        method: 'post',
        params: {},
        headers: {}
    },
    deleteToken: {
        url: '/push-notification/unsubscribe-by-token',
        method: 'post',
        params: {},
        headers: {}
    },
}
