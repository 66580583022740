import * as actionTypes from '../actions/actionTypes'

const initialState = {
	showSidebar: false
}

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.TOGGLE_SIDEBAR_MENU: return toggleSidebar(state)
		default: return state;
	}
}, toggleSidebar = (state) => {
	return {
		showSidebar: !state.showSidebar
	}
}

export default reducer