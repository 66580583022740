import React from 'react'
import {Link} from 'react-router-dom'

export default (props) => {
	return (
		<Link
			to={"/user/edit"}
			className="user-card sidebar__user-card d-flex align-content-center justify-content-start"
		>
			<div className="user-card__avatar">
				<img src={props.avatar} alt="avatar" />
			</div>
			<div className="user-card__info">
				<div className="user-card__name">{props.firstLastName}</div>
				<div className="user-card__email">{props.email}</div>
			</div>
		</Link>
	)
}