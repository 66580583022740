import { SET_USER_INFO } from '../actionTypes'
import API from "../../../helper/http/apiCalls";

export const setUserInfo = (userInfo) => {
	return {
		type: SET_USER_INFO,
		userInfo
	}
}

export const getUserInfo = () => {
	return async (dispatch, getState) => {
		try {
			let userInfo = await API.call('getUserInfo')

			dispatch(setUserInfo(userInfo))

		} catch(err) { console.error(err) }
	}
}