import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
// import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, Container, Row, Col, Jumbotron, Button } from 'reactstrap';

import * as serviceWorker from './serviceWorker'
import App from './App'
import store from './store/index'
import * as actions from './store/actions/index'

import './assets/scss/main.scss'

let token = JSON.parse(localStorage.getItem('token'))
if (token && token.access_token) { store.dispatch(actions.setToken(token)) }

ReactDOM.render(
	<Provider store={store}>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</Provider>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
window.DD_RUM && window.DD_RUM.init({
    clientToken: process.env.REACT_APP_DATADOG_TOKEN,
    applicationId: process.env.REACT_APP_DATADOG_ID,
    trackInteractions: true,
  });