export default {
	getPaymentInfo: {
		url: '/payment/bank/list',
		method: 'get',
		params: {},
		headers: {}
	},
	getPaymentUrl: {
		url: '/payment/pay',
		method: 'post',
		params: {},
		headers: {}
	},
	paymentResponse: {
		url: '/payment/response',
		method: 'post',
		params: {},
		headers: {}
	},
}