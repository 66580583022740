export default {
	getFaq: {
		url: '/faq/group/sorted',
		method: 'get',
		params: {},
		headers: {}
	},
	getInvFaq: {
		url: '/faq/group/invoice-related/',
		method: 'get',
		params: {},
		headers: {}
	}
}