import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'

import * as actions from "../../../store/actions"

import avatar from '../../../assets/img/avatar.svg'

import ProfileBlock from './components/ProfileBlock'

class Sidebar extends Component {
	componentDidMount() {
		this.props.getUserInfo()
	}

	render() {
		return (
			<div className={`col-12 col-lg-4 col-xl-3 sidebar-wrapper ${this.props.showSidebar ? 'is-active' : ''}`}>
				<div className="sidebar" onClick={this.props.toggleMenu}>
					<ProfileBlock
						avatar={this.props.userInfo.avatar || avatar}
						firstLastName={this.props.userInfo.firstLastName}
						email={this.props.userInfo.email}
					/>
					<div className="user-menu">
						<NavLink to="/" className="user-menu__link" activeClassName="active">
							<i className="user-menu__icon far fa-glasses"></i>
							JOUW OVERZICHT
						</NavLink>
						<NavLink to="/betaalde-facturen" className="user-menu__link" activeClassName="active">
							<i className="user-menu__icon far fa-clipboard-check"></i>
							BETAALDE FACTUREN
						</NavLink>
						<a onClick={this.props.logOut}
						   className="user-menu__link user-menu__link--login"
						   style={{cursor: 'pointer'}}
						>
							<i className="user-menu__icon far fa-sign-out "></i>
							UITLOGGEN
						</a>
					</div>
				</div>
			</div>
		)
	}
}

let mapStateToProps = state => {
	return {
		showSidebar: state.sidebarReducer.showSidebar,
		userInfo: state.userInfoReducer.userInfo
	}
}, dispatchToProps = dispatch => {
	return {
		logOut: () => dispatch(actions.removeToken()),
		toggleMenu: () => dispatch(actions.toggleSidebarMenu()),
		getUserInfo: () => dispatch(actions.getUserInfo())
	}
}

export default connect(mapStateToProps, dispatchToProps)(Sidebar);
