import * as actionTypes from '../actions/actionTypes'

const initialState = {
	userInfo: {}
}

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_USER_INFO: return setUserInfo(state, action)
		default: return state;
	}
}, setUserInfo = (state, { userInfo }) => {
	return {
		...state,
		userInfo: {...userInfo}
	}
}

export default reducer